import $ from 'jquery';
window.$ = $;
window.jQuery = $;
require('bootstrap-sass');

function scrollFunction() {
    var posScroll = $(document).scrollTop();
    if(posScroll >= 50)
    {
        $('.scroll-to-top').fadeIn(600);
    }
    else
    {
        $('.scroll-to-top').fadeOut(600);
    }

    if(posScroll >= 70)
    {
        $('body').addClass("top-menu");
    }
    else
    {
        $('body').removeClass("top-menu");
    }
}


function scrollInit() {
    $('.js-scrollTo').on('click', function () {
        var goscroll = false;
        var the_hash = $(this).attr("href");
        var regex = new RegExp("(.*)\#(.*)", "gi");
        var the_element = '';
        if (the_hash.match("\#(.+)")) {

            the_hash = the_hash.replace(regex, "$2");

            if ($("#" + the_hash).length > 0) {
                the_element = "#" + the_hash;
                goscroll = true;
            }
            else if ($("a[name=" + the_hash + "]").length > 0) {
                the_element = "a[name=" + the_hash + "]";
                goscroll = true;
            }

            if (goscroll) {
                var headerHeight = $('header').outerHeight();
                var to = $(the_element).offset().top - headerHeight - 20;
                $('html, body').animate({
                    scrollTop: to
                }, 750);
                return false;
            }
        }
    });
}
window.onscroll = function() {
    scrollFunction();
};

$('.scroll-to-top').on("click", function(e){
    e.preventDefault();
    $('html, body').animate({
        scrollTop: 0
    }, 750);
});

export default scrollInit;
