import $ from 'jquery';
import Modal from "./Modal";

function singleProduct()
{
    // Si la page ouverte est un ProductSingle
    if($(".share_montableau_page_btn").length != 0){

        $(".share_montableau_page_btn").on("click", new Modal().openShareModal);
        $(".background-close-modal").on("click", new Modal().closeShareModal);

        new Modal().setUrls();
    }

    if($(".share-montableau-product").length != 0){
        $(".share-montableau-product").on("click", function(e){
            e.preventDefault();
            new Modal().shareProduct(this);
        });
    }

    if($(".add-product-favorites").length != 0){
        $(".add-product-favorites").on("click", function(e){
            e.preventDefault();
            new Modal().addToFavoritesProduct(this);
        });
    }

    if($(".cwa_modal").length != 0){
        $(".background-close-modal").on("click", function(){
            $(".cwa_modal").fadeOut();
        });
    }
}

export default singleProduct;
