import $ from 'jquery';

function gestPayment() {

    let alerts = {
        'formNoValid' : {
            'fr': "Veuillez sélectionner un mode de paiement et accepter les  Conditions Générales de Ventes et d'Utilisation.",
            'en' : "Please select a payment method and accept the General Conditions of Sale and Use."
        },
    };


    // Si la page de paiement est ouverte
    if ($("#gest-payment").length != 0) {
        $(".all_payment_methods_infos .link").on("click", function(){
            setPaymentMethod(this);
        });

        var allInputs = $("input[name='payment_form[paymentMethod][method]");
        if(allInputs.filter(":checked").val() !== undefined){
            var selectID = allInputs.filter(":checked").attr("id");
            $(".all_payment_methods_infos .link[data='"+selectID+"']").addClass("active");
        }

        $(".valid-payment-button").on("click", function (e){
            if($("#payment_form_accept").val() != true || (!$('#payment_form_accept:checked').val())){
                e.preventDefault();
                var locale = $("html").attr("lang");
                $("#modal_alert_order_item h3").html(alerts["formNoValid"][locale]);
                $("#modal_alert_order_item .buttons.login").hide();
                $("#modal_alert_order_item #alert-order-no").hide();
                $("#modal_alert_order_item").fadeIn();
            }
        });
    }
}

function setPaymentMethod(button){
    $("input[name='payment_form[paymentMethod][method]']").attr("checked", false);
    var method = button.getAttribute("data");
    $("#"+method).attr("checked", true);
    $(".all_payment_methods_infos .link").removeClass("active");
    button.classList.add('active');
}

export default gestPayment;
