import $ from 'jquery';

function rentSingleProduct() {
    // Si la page ouverte est un ProductSingle qu'on peut louer
    if ($("#rent-this-product").length != 0) {

        $("#rent-this-product").on("click", openRentModal);
        $(".background-close-modal").on("click", closeRentModal);

        $(".next-month").on("click", function(){
            setRentCalendar(true);
        });
        $(".previous-month").on("click", function(){
            setRentCalendar(false);
        });

        $(".date-button").on("click", function(){
            setRentDate(this);
        });

        $(".reset-rent").on("click", removeRentDates);
        $(".submit-rent").on("click", validRentProduct);
    }
}

function openRentModal(){
    $("#modal_rent_product").fadeIn();
}

function closeRentModal(){
    $("#modal_rent_product").fadeOut();
}

function setRentCalendar(isNext){
    var actualDate = $("#first-month").val();

    $("#calendar_interactive_datas").html("<div class='ajax-loader'></div>");

    actualDate = actualDate.split("/");

    var newMonth = parseInt(actualDate[0]);
    var newYear = parseInt(actualDate[1]);

    if(isNext){
        if(newMonth == 12){
            newMonth = 1;
            newYear++;
        } else {
            newMonth++;
        }
    } else {
        if(newMonth == 1){
            newMonth = 12;
            newYear--;
        } else {
            newMonth--;
        }
    }

    var newDate = newMonth+"/01/"+newYear;

    $.ajax({
        url: '/ajax/set_rent_calendar',
        type: 'POST',
        data: jQuery.param({
            startAt : newDate
        }) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            $("#calendar_interactive_datas").html(jqxhr);

            $(".next-month").on("click", function(){
                setRentCalendar(true);
            });
            $(".previous-month").on("click", function(){
                setRentCalendar(false);
            });
            $(".date-button").on("click", function(){
                setRentDate(this);
            });

            $(".reset-rent").on("click", removeRentDates);

            setActiveDates();
        },
        error: function () {
            console.log("Set Calendar Error");
            return ;
        }
    });
}

function setRentDate(element){
    var date = element.getAttribute("data");
    var startAt = $("#data-rent").attr("startat");
    var stopAt = $("#data-rent").attr("stopat");

    var startAtDate = new Date(startAt);
    var actualDate = new Date(date);

    if(startAt == "" || actualDate - startAtDate < 0){
        $("#data-rent").attr("startat", date);
    } else {
        $("#data-rent").attr("stopat", date);
    }

    setActiveDates();
}

function setActiveDates(){
    $(".days-number .active").removeClass("active");
    var startAt = $("#data-rent").attr("startat");
    var stopAt = $("#data-rent").attr("stopat");

    $("[data='"+startAt+"']").addClass('active');
    $("[data='"+stopAt+"']").addClass('active');

    var startAtLabel = "A définir";
    var stopAtLabel = "A définir";

    if(startAt != "") {
        var startAtArray = startAt.split("/");
        startAtLabel = startAtArray[1] + " " + getMonthLabel(parseInt(startAtArray[0]));
    }

    if(stopAt != ""){
        var stopAtArray = stopAt.split("/");
        stopAtLabel = stopAtArray[1] +" "+ getMonthLabel(parseInt(stopAtArray[0]));

        startAt = new Date(startAt);
        stopAt = new Date(stopAt);

        var price = Math.ceil(((stopAt - startAt) / 86400000 + 1) * 10);

        var oldTextBtn = $(".submit-rent").html().split("-");
        var currency = $("#currency_data_product").val();

        $.ajax({
            url: '/ajax/convert_price',
            type: 'POST',
            data: jQuery.param({
                price: price,
                money_type: currency,
            }) ,
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            success: function (jqxhr) {
                var newTextBtn = oldTextBtn[0]+" - "+jqxhr.price;
                $(".submit-rent").html(newTextBtn);
            },
            error: function () {
                console.log("Get Price Error");
                return ;
            }
        });

        $(".submit").fadeIn();
    }

    $(".start .date").html(startAtLabel);
    $(".stop .date").html(stopAtLabel);
}

function removeRentDates(){
    $(".submit").fadeOut();
    $(".days-number .active").removeClass("active");
    $("#data-rent").attr("startat", "");
    $("#data-rent").attr("stopat", "");
    $(".start .date").html("A définir");
    $(".stop .date").html("A définir");
}

function getMonthLabel(month){
    var label = "";
    
    switch (month){
        case 1:
            label = "janv.";
            break;
        case 2:
            label = "févr.";
            break;
        case 3:
            label = "mars";
            break;
        case 4:
            label = "avril";
            break;
        case 5:
            label = "mai";
            break;
        case 6:
            label = "juin";
            break;
        case 7:
            label = "juil.";
            break;
        case 8:
            label = "août";
            break;
        case 9:
            label = "sept.";
            break;
        case 10:
            label = "oct.";
            break;
        case 11:
            label = "nov.";
            break;
        case 12:
            label = "dec.";
            break;
    }
    
    return label;
}

function validRentProduct(){
    $("#modal_alert_product_item .content h3").html("<div class='ajax-loader'></div>");
    $("#modal_alert_product_item").fadeIn();
    closeRentModal();

    let alert = {
        "fr": "Location ajoutée dans le panier !",
        "en": "Rental added to cart !"
    }

    var id = $("#id_data_product").val();
    var startAt = $("#data-rent").attr("startat");
    var stopAt = $("#data-rent").attr("stopat");
    var locale = $("#app_locale_data").val();

    $.ajax({
        url: '/ajax/add_product_to_cart',
        type: 'POST',
        data: jQuery.param({
            id: id,
            startAt: startAt,
            stopAt: stopAt
        }) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            $("#modal_alert_product_item .content h3").html("<i class=\"fas fa-check\"></i><br>"+alert[locale]);
        },
        error: function () {
            $("#modal_alert_product_item").fadeOut();
            console.log("Add to cart Error");
            return ;
        }
    });
}

export default rentSingleProduct;
