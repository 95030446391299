import $ from 'jquery';

function imgCopiedForm()
{
    if($(".img-upload-input").length > 0){

        $(".img-upload-input").on("change", previewFile);

    }
}

function previewFile() {
    var preview = document.querySelector('.img-preview');
    var file    = document.querySelector('.img-upload-input').files[0];
    var reader  = new FileReader();

    reader.onloadend = function () {
        preview.src = reader.result;
    }

    if (file && validateFile()) {
        reader.readAsDataURL(file);
        $(".img-input").addClass('active');
    } else {
        preview.src = "";
        $(".img-input").removeClass('active');
        document.querySelector('.img-upload-input').value = null;
    }
}

function validateFile()
{
    var alertMessage = {
        'fr' : "Seul les images sont acceptées !",
        'en' : 'Only images are accepted !'
    }

    var locale = $("html").attr("lang");

    var allowedExtension = ['jpeg', 'jpg', 'png'];
    var fileExtension = document.querySelector('.img-upload-input').value.split('.').pop().toLowerCase();
    var isValidFile = false;

    for(var index in allowedExtension) {

        if(fileExtension === allowedExtension[index]) {
            isValidFile = true;
            break;
        }
    }

    if(!isValidFile) {
        alert(alertMessage[locale]);
    }

    return isValidFile;
}

export default imgCopiedForm;