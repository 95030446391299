const $ = require('jquery');
window.$ = $;
window.jQuery = $;
require('bootstrap-sass');

function tooltipInit() {
    $('[data-toggle="tooltip"]').tooltip({delay: {show: 0, hide: 100000}});

    $(document).ajaxComplete(function (event, xhr, settings) {
        $('[data-toggle="tooltip"]').tooltip();
    });
}

export default tooltipInit;
