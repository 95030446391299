import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'select2';                       // globally assign select2 fn to $ element

export default function init() {
    $('.js-advanced-select').select2();

    $(document).ajaxComplete(function (event, xhr, settings) {
        $('.js-advanced-select').select2();
    });
}




