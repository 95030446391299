import $ from 'jquery';

/***** ATTENTION : Les appels Ajax de ce fichier se trouvent dans OrderController ********/

function gestOrder()
{
    let alerts = {
        'noconnect' : {
            'fr': "Veuillez vous connecter pour continuer",
            'en' : "Please login to continue"
        },
        'noaddress' : {
            'fr': "Veuillez choisir une adresse de livraison",
            'en': "Please choose a delivery address"
        }
    };


    // Si la page ouverte est le panier
    if($("#gest-order").length != 0){

        //GESTION QUANTITE
        $(".setting_qt_input").on("blur", function(){
            setQuantity(this);
        });

        $(".setting_size_button_up").on("click", function(){
            this.parentNode.querySelector('[type=number]').stepUp();
            setQuantity(this.parentNode.querySelector('[type=number]'));
        });

        $(".setting_size_button_down").on("click", function(){
            this.parentNode.querySelector('[type=number]').stepDown();
            setQuantity(this.parentNode.querySelector('[type=number]'));
        });

        //SUPPRIMER UN ITEM
        $(".delete-order-item").on("click", function(){
            openRemoveModal(this.getAttribute("data-id"));
        });
        $("#delete-order-item-yes").on("click", function(){
            deleteOrderItem(this.getAttribute("data-id"));
        });

        $(".background-close-modal").on("click", closeRemoveModal);
        $("#delete-order-item-no").on("click", closeRemoveModal);

        //GERER LES PROMOTIONS
        $("#discount-submit").on("click", addDiscount);

        //GERER LA VALIDATION
        $("#valid-order").on("click", function(){
            validOrder(alerts);
        });
    }
}

function openRemoveModal(id){
    $("#modal_delete_order_item").fadeIn();
    $("#delete-order-item-yes").attr("data-id", id);
}

function closeRemoveModal(){
    $("#modal_delete_order_item").fadeOut();
    $("#modal_alert_order_item").fadeOut();
}

function setQuantity(element){
    var itemID = element.getAttribute('data-id');
    var quantity = element.value;
    if(quantity <= 0){
        return ;
    }

    ajaxBlur($(".price-"+itemID));
    ajaxBlur($(".price-ttc-data"));
    ajaxBlur($(".shipping-price-data"));
    ajaxBlur($(".price h3"));

    var currency = $("#currency_data_product").val();

    $.ajax({
        url: '/ajax/set_order_item_quantity',
        type: 'POST',
        data: jQuery.param({
            id: itemID,
            quantity: quantity,
            money_type: currency,
        }) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            $(".price-"+itemID).html(jqxhr.priceItem);
            $(".price-ttc-data").html(jqxhr.priceTTC);
            $(".shipping-price-data").html(jqxhr.shippingCost);
            $(".price h3").html(jqxhr.priceOrder);

            ajaxBlur($(".price-"+itemID), false);
            ajaxBlur($(".price-ttc-data"), false);
            ajaxBlur($(".shipping-price-data"), false);
            ajaxBlur($(".price h3"), false);
        },
        error: function () {
            console.log("Set Quantity Error");
            return ;
        }
    });
}

function deleteOrderItem(itemID){
    $("#modal_remove_item_content").html("<div class='ajax-loader'></div>");
    $.ajax({
        url: '/ajax/remove_order_item',
        type: 'POST',
        data: jQuery.param({
            id: itemID
        }) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            document.location.reload();
        },
        error: function () {
            console.log("Remove Order Item Error");
            return ;
        }
    });
}

function addDiscount(){
    var code = $("#discount-value").val();
    var idOrder = $("#order_id").val();
    var currency = $("#currency_data_product").val();

    ajaxBlur($("#discount-value"));
    ajaxBlur($(".price h3"));
    ajaxBlur($(".price-ttc-data"));

    $.ajax({
        url: '/ajax/add_discount',
        type: 'POST',
        data: jQuery.param({
            code: code,
            idOrder: idOrder,
            money_type: currency,
        }) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            if(jqxhr.alerten){
                if($("#app_locale_data").val() == "fr")
                    $("#discount-value").val(jqxhr.alertfr);
                else
                    $("#discount-value").val(jqxhr.alerten);
            } else {
                $("#discount-value").prop('disabled', true);
                $(".price h3").html(jqxhr.priceOrder);
                $(".price-ttc-data").html(jqxhr.price);
                $("#discount-submit").addClass("discount-selected");
                $("#discount-submit").html("<i class=\"fas fa-check\"></i>");
            }
            ajaxBlur($("#discount-value"), false);
            ajaxBlur($(".price h3"), false);
            ajaxBlur($(".price-ttc-data"), false);
        },
        error: function () {
            console.log("Add Discount Code Error");
            return ;
        }
    });

}

function validOrder(alerts){
    var locale = $("#app_locale_data").val();
    var idOrder = $("#order_id").val();

    if($("#app_user_data").length == 0){
        $("#modal_alert_order_item h3").html(alerts["noconnect"][locale]);
        $("#modal_alert_order_item .buttons.address").hide();
        $("#modal_alert_order_item").fadeIn();

        return;
    }

    if(!$('.checkbox-addresses:checked').val()){
        $("#modal_alert_order_item h3").html(alerts["noaddress"][locale]);
        $("#modal_alert_order_item .buttons.login").hide();
        $("#modal_alert_order_item").fadeIn();

        return null;
    }

    var address = $('.checkbox-addresses:checked').val();

    $("#modal_alert_order_item .content").html("<div class='ajax-loader'></div>");
    $("#modal_alert_order_item").fadeIn();

    $.ajax({
        url: '/ajax/set_order_address',
        type: 'POST',
        data: jQuery.param({
            id: idOrder,
            address: address
        }) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            var url = $("#order_url_valid_data").val();
            window.location.pathname = url;
        },
        error: function () {
            console.log("Confirm Order Error");
            return ;
        }
    });
}

function ajaxBlur(element, active=true){
    if(active == true){
        element.css({
            'filter'         : 'blur(5px)',
            '-webkit-filter' : 'blur(5px)',
            '-moz-filter'    : 'blur(5px)',
            '-o-filter'      : 'blur(5px)',
            '-ms-filter'     : 'blur(5px)'
        });
    } else {
        element.css({
            'filter'         : 'blur(0px)',
            '-webkit-filter' : 'blur(0px)',
            '-moz-filter'    : 'blur(0px)',
            '-o-filter'      : 'blur(0px)',
            '-ms-filter'     : 'blur(0px)'
        });
    }
}

export default gestOrder;
