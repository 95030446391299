import $ from 'jquery';

function pageResize() {
    var windowHeight = $( window ).height();
    var headerHeight = ($('header').length > 0) ? $('header').outerHeight() : 0;
    var breadcrumbHeight = ($('.breadcrumb-wrapper').length > 0) ? $('.breadcrumb-wrapper').outerHeight() : 0;
    var footerHeight = ($('footer').length > 0) ? $('footer').outerHeight() : 0;

    var mainHeight = windowHeight  - footerHeight - headerHeight - breadcrumbHeight;

    $('main').css('min-height', mainHeight + 'px');;
}


function resizeInit() {
    $(window).resize(function () {
        pageResize();
    });
    pageResize();
}
export default resizeInit;
