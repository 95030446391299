import $ from 'jquery';

function singleProduct()
{
    // Si la page ouverte est un ProductSingle
    if($("#buy_product").length != 0){

        //*********** ORIGINAL ET COPIE ******************/
        //Checkbox
        $(".checkbox-finishes").on("click", setFinishes);
        $(".checkbox-frames").on("click", setFinishes);

        $("#add-cart-product").on("click", addProductOnCart);

        $(".ask_enlarged_page_btn").on("click", function(){
            $("#modal_ask_enlarged_product").fadeIn();
            $("#enlarged_product_orderProduct").val($("#id_data_product").val());
        });

        //*********** COPIE ******************/
        //Size
        var ratio = $("#ratio_data_product").val();

        $(".setting_size_input").on("blur", function(){
            setSize(this, ratio);
        });

        $(".setting_size_button_up").on("click", function(){
            this.parentNode.querySelector('[type=number]').stepUp()
            setSize(this.parentNode.querySelector('[type=number]'), ratio);
        });

        $(".setting_size_button_down").on("click", function(){
            this.parentNode.querySelector('[type=number]').stepDown()
            setSize(this.parentNode.querySelector('[type=number]'), ratio);
        });

        $(".add_to_favorites_btn").on("click", addProductToFavorites);

        $(".background-close-modal").on("click", function(){
            $(".cwa_modal").fadeOut();
        })

        //********************* ORIGINAL ***************/
        $("#data-original-size").select2({
            minimumResultsForSearch: -1
        });
        $("#data-original-size").on("change", setOriginalPrice);
    }

}

function setSize(element)
{
    let type = element.getAttribute("name");
    let height = 0;
    let width = 0;

    let ratio = $("#ratio_data_product").val();

    if(type == "width"){
        width = element.value;
        if(width <= 0){
            return ;
        }
        height = Math.ceil(width * ratio);
        $("#data-height").val(height);
    } else {
        height = element.value;
        if(height <= 0){
            return ;
        }
        width = Math.ceil(height / ratio);
        $("#data-width").val(width);
    }

    setPrice(width, height);
}

function setFinishes(){
    var finishes = $("input[name='finishes']:checked").val();

    if(finishes == "multi-frame")
        getFrames();
    else
        getFrames(false);

    //Si c'est une oeuvre copîée
    if($("#data-original-size").length == 0)
        setPrice(null, null);
    else
        setOriginalPrice();
}

function getFrames(display = true){
    if(display)
        $("#frames-choices").fadeIn();
    else
        $("#frames-choices").fadeOut();
}

function setPrice(width, height)
{
    var frame = $("input[name='frame']:checked").val();
    var grade = $("#grade_data_product").val();
    var currency = $("#currency_data_product").val();
    var finishes = $("input[name='finishes']:checked").val();

    if(width == null)
        width = $("#data-width").val();
    if(height == null)
        height = $("#data-height").val();

    $(".data-price").html("<div class='ajax-loader'></div>");
    $.ajax({
        url: '/ajax/set_product_size',
        type: 'POST',
        data: jQuery.param({
            width: width,
            height: height,
            grade: grade,
            money_type: currency,
            finishes: finishes,
            frame: frame
        }) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            $(".data-price").html(jqxhr.price);
        },
        error: function () {
            console.log("Get Price Error");
            return ;
        }
    });
}

function setOriginalPrice()
{
    var size = $("#data-original-size").val();
    var frame = $("input[name='frame']:checked").val();
    var currency = $("#currency_data_product").val();
    var finishes = $("input[name='finishes']:checked").val();

    $(".data-price").html("<div class='ajax-loader'></div>");
    $.ajax({
        url: '/ajax/set_original_product_size',
        type: 'POST',
        data: jQuery.param({
            size: size,
            money_type: currency,
            finishes: finishes,
            frame: frame
        }) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            $(".data-price").html(jqxhr.price);
        },
        error: function () {
            console.log("Get Price Error");
            return ;
        }
    });
}

function addProductOnCart(){
    // Si c'est oeuvres copiés
    if($("#data-original-size").length > 0){
        addOriginalProductOnCart();
        return;
    }

    $("#modal_alert_product_item .content h3").html("<div class='ajax-loader'></div>");
    $("#modal_alert_product_item").fadeIn();

    let alert = {
        "message" :{
            "fr": "Ajouté au panier !",
            "en": "Add to cart !"
        } ,
        "link": {
            "fr": "/fr/panier",
            "en": "/en/cart"
        } ,
        "buttonContent": {
            "fr": "Voir mon panier",
            "en": "See my cart"
        }
    }

    var id = $("#id_data_product").val();
    var width = $("#data-width").val();
    var finishes = $("input[name='finishes']:checked").val();
    var frame = $("input[name='frame']:checked").val();
    var locale = $("#app_locale_data").val();

    var goodMessage = "<i class=\"fas fa-check\"></i><br>"+alert['message'][locale];
    goodMessage += "<div><a href=\""+alert['link'][locale]+"\" class=\"modal-no-btn btn\" >"+ alert['buttonContent'][locale] +"</a></div>";

    $.ajax({
        url: '/ajax/add_product_to_cart',
        type: 'POST',
        data: jQuery.param({
            id: id,
            width: width,
            finishes: finishes,
            frame: frame
        }) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            $("#modal_alert_product_item .content h3").html(goodMessage);
            setTimeout(
                function()
                {
                    $("#modal_alert_product_item").fadeOut();
                }, 4000);
        },
        error: function () {
            $("#modal_alert_product_item").fadeOut();
            console.log("Add to cart Error");
            return ;
        }
    });
}

function addOriginalProductOnCart(){
    $("#modal_alert_product_item .content h3").html("<div class='ajax-loader'></div>");
    $("#modal_alert_product_item").fadeIn();

    let alert = {
        "message" :{
            "fr": "Ajouté au panier !",
            "en": "Add to cart !"
        } ,
        "link": {
            "fr": "/fr/panier",
            "en": "/en/cart"
        } ,
        "buttonContent": {
            "fr": "Voir mon panier",
            "en": "See my cart"
        }
    }

    var id = $("#id_data_product").val();
    var size = $("#data-original-size").val();
    var finishes = $("input[name='finishes']:checked").val();
    var frame = $("input[name='frame']:checked").val();
    var locale = $("#app_locale_data").val();

    var goodMessage = "<i class=\"fas fa-check\"></i><br>"+alert['message'][locale];
    goodMessage += "<div><a href=\""+alert['link'][locale]+"\" class=\"modal-no-btn btn\" >"+ alert['buttonContent'][locale] +"</a></div>";

    $.ajax({
        url: '/ajax/add_original_product_to_cart',
        type: 'POST',
        data: jQuery.param({
            id: id,
            size: size,
            finishes: finishes,
            frame: frame
        }) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            $("#modal_alert_product_item .content h3").html(goodMessage);
            setTimeout(
                function()
                {
                    $("#modal_alert_product_item").fadeOut();
                }, 4000);
        },
        error: function () {
            $("#modal_alert_product_item").fadeOut();
            console.log("Add to cart Error");
            return ;
        }
    });
}

function addProductToFavorites()
{
    $("#modal_alert_product_item .content h3").html("<div class='ajax-loader'></div>");
    $("#modal_alert_product_item").fadeIn();

    let alert = {
        "fr": "Ajouté au favoris !",
        "en": "Add to Favorites !"
    }

    let id = $("#id_data_product").val();
    var locale = $("#app_locale_data").val();
    $.ajax({
        url: '/ajax/add_to_favorites',
        type: 'POST',
        data: jQuery.param({
            id: id
        }) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            $("#modal_alert_product_item .content h3").html("<i class=\"fas fa-check\"></i><br>"+alert[locale]);
        },
        error: function () {
            $("#modal_alert_product_item").fadeOut();
            console.log("Get Error for Add To Favorites");
            return ;
        }
    });
}

export default singleProduct;
