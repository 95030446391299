import _cookieConsent from './_cookie-consent';
import _videoLink from './_video-link';
import _scrollTo from "./_scroll-to";
import _toolTip from "./_tooltip";
import _advancedSelect from "./_advanced-select";
import _pageResize from "./_page-rezize";

import _singleProduct from "./_products";
import _listProducts from "./_products_list";
import _rentSingleProduct from "./_rent_product";
import _settingShareModal from "./_modal_all_pages";
import _gestOrder from "./_orders";
import _gestPayment from "./_payment";
import _imgCopiedForm from "./_img_copied_form";
import _faqPagePart from "./_faq_pp";

import $ from "jquery";

document.addEventListener('DOMContentLoaded', () => {

});

(function ($) {
    $(function () {

    });
})(jQuery);


$(document).ready(function () {

    setWebsiteViews();
    var url_string = window.location.href;
    var url = new URL(url_string);
    var searchGetParameter = url.searchParams.get("search");

    if(searchGetParameter == "true"){
        setWebsiteSearch();
    }


    // ALL PAGES
    if($(".sub-footer__info a[href='https://www.agence-cwa.com/']").length > 0){
        var cwaLink = $(".sub-footer__info a[href='https://www.agence-cwa.com/']").html();
        cwaLink = cwaLink.replace('CWA', '<span class="logo-cwa">cwa</span>');
        $(".sub-footer__info a[href='https://www.agence-cwa.com/']").html(cwaLink);
    }

    $(".search-header-link").on("click", function(e){
        e.preventDefault();
        if($(".search-header-input").hasClass("active")){
            $(".search-header-input").removeClass("active");
        } else {
            $(".search-header-input").addClass("active");
        }
    });

    $(".new-email-newsletter-btn").on("click", function(e) {
        if (validateEmail($("#newsletter-email-input").val()) != false) {
            e.preventDefault();
            addNewsletterEmail();
        }
    });

    //PAGEPART CONTACT FORM
    $(".submit-contact-form").on("click", function(e){
        if(
            validateEmail($("input[name='email']").val()) != false
            && $("input[name='surname']").val() != ""
            && $("textarea[name='message']").val() != ""
        ){
            e.preventDefault();
            sendContactForm();
        }
    });


    //MOBILE - NAVBAR

        //SEE SECTION
    $(".menu-mobile-js-buton").on('click', function(e){
        e.preventDefault();
        $("#menu-mobile-content").addClass("active");
        $("body").css({"overflow":"hidden"});
    });

    $(".close-menu-mobile-content").on('click', function(e){
        e.preventDefault();
        $("#menu-mobile-content").removeClass("active");
        $("body").css({"overflow-y":"visible"});
    });

        //SEE SUB ITEMS
    $("#menu-mobile-content ul.level0 li.has-children").height($("#menu-mobile-content ul.level0 li.has-children a").outerHeight());

    $("#menu-mobile-content .has-children > a").on("click", function(e){
        e.preventDefault();

        if($(this).hasClass("active")){
            $(this).removeClass("active");
            var newHeight = $(this).outerHeight();
            $(this).parent().animate({
                height: newHeight+"px"
            }, 500);
        } else {
            $(this).addClass("active");
            var littleHeight = $(this).parent().outerHeight();
            var newHeight = littleHeight + $(this).next("ul").outerHeight();

            $(this).parent().animate({
                height: newHeight+"px"
            }, 500);
        }
    });

    $(".pp_site_map a[href='#']").on("click", function (e) {
        e.preventDefault();
    });

    //Description des artistes
    if($(".artist_description").length > 0){
        var actualHeight = $(".artist_description").outerHeight();
        if(actualHeight > 57){
            $(".artist_description").animate({
                height: "56px"
            }, 100);
        } else {
            $(".artist_description_see_more").remove();
        }

        $(".artist_description_see_more").on("click", function(e) {
            e.preventDefault();
            $(".artist_description").animate({
                height: actualHeight + "px"
            }, 500);
            $(".artist_description_see_more").remove();
        })
    }

    //Lien cookie
    var locale = $("html").attr("lang");
    $("#cookie-bar a").attr("href", "/"+locale+"/politiques-des-cookies");


    //OTHERS
    _cookieConsent();
    _videoLink();
    _scrollTo();
    _toolTip();
    _advancedSelect();
    _pageResize();

    _singleProduct();
    _listProducts();
    _rentSingleProduct();
    _settingShareModal();
    _gestOrder();
    _gestPayment();
    _imgCopiedForm();
    _faqPagePart();
});

function addNewsletterEmail(){
    $("#modal_alert_product_item .content h3").html("<div class='ajax-loader'></div>");
    $("#modal_alert_product_item").fadeIn();

    var value = $("#newsletter-email-input").val();
    var locale = $("html").attr("lang");

    let alert = {
        "fr": "Email bien ajouté !",
        "en": "Email added !"
    }

    $.ajax({
        url: '/ajax/add_email_newsletter',
        type: 'POST',
        data: jQuery.param({
            email: value
        }) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            $("#modal_alert_product_item .content h3").html("<i class=\"fas fa-check\"></i><br>"+alert[locale]);
        },
        error: function () {
            $("#modal_alert_product_item").fadeOut();
            console.log("Get Error for Add Email to Newsletter List");
            return ;
        }
    });
}

function setWebsiteViews(){
    $.ajax({
        url: '/ajax/set_website_views',
        type: 'POST',
        data: jQuery.param({}) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {},
        error: function () {
            console.log("Get Error for set Website Views");
            return ;
        }
    });
}

function setWebsiteSearch(){
    $.ajax({
        url: '/ajax/set_website_search',
        type: 'POST',
        data: jQuery.param({}) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {},
        error: function () {
            console.log("Get Error for set Website Views");
            return ;
        }
    });
}

function sendContactForm(){
    $("#modal_alert_product_item .content h3").html("<div class='ajax-loader'></div>");
    $("#modal_alert_product_item").fadeIn();

    var contactForm = document.getElementById("contact_form");
    var formData = new FormData(contactForm);
    formData.append('image', document.getElementById("img_copied_fileData").files[0]);

    // var surname = $("input[name='surname']").val();
    // var email = $("input[name='email']").val();
    // var mobile = $("input[name='mobile']").val();
    // var message = $("textarea[name='message']").val();
    // var image = $("input[name='image']").prop('files')[0];
    //console.log(formData);

    var locale = $("html").attr("lang");

    let alert = {
        "fr": "Message bien envoyé !",
        "en": "Message send !"
    }

    $.ajax({
        url: '/ajax/add_contact_form',
        type: 'POST',
        dataType: 'text',  // what to expect back from the PHP script, if anything
        cache: false,
        contentType: false,
        processData: false,
        data: formData,
        success: function (jqxhr) {
            $("#modal_alert_product_item .content h3").html("<i class=\"fas fa-check\"></i><br>"+alert[locale]);
        },
        error: function () {
            $("#modal_alert_product_item").fadeOut();
            console.log("Get Error for Add Email to Newsletter List");
            return ;
        }
    });
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
