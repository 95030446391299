import $ from 'jquery';
import Modal from "./Modal";

function listProducts()
{
    // Si la page Liste des oeuvres COPIEES est ouverte
    if($(".all-copied-products").length != 0)
    {
        $(".filters-content").on("click", ".artist-filter-show-more", function(e){
            e.preventDefault();
            viewMoreFilters("artists", this);
        });

        $(".filters-movements").on("click", ".category-filter-show-more", function(e){
            e.preventDefault();
            viewMoreFilters("movements", this);
        });

        $(".filter-item").on("click", function(e){
            e.preventDefault();
            setFilters(this);
        });
    }

    //Si la page Liste des oeuvres ORIGINALES est ouverte
    if($(".all-original-products").length != 0){
        $(".filter-item-category").on("click", function (e){
            e.preventDefault();
            setFilters(this);
        });
    }

    //Si au moins un input de recherche existe
    if($(".input_section_search").length != 0){
        ajaxBlur( $(".input_section_search"), true);
        getSearchItems();
    }

    $(".remove-filters").fadeOut();
    $(".remove-filters").on("click", function(e) {
        e.preventDefault();
        removeAllFilters();
    });
}

function viewMoreFilters(type, button){
    const buttonText = {
        "more" : {
            "fr": "Voir plus",
            "en": "See more"
        },
        "less" : {
            "fr" : "Voir moins",
            "en": "See less"
        }
    };
    var langage = $("html").attr("lang");
    var element = $(".filters-"+type+" .all-filters.hidden-filters");

    if(element.css("height") != "0px"){
        element.css({
            height: "0px",
            visibility: "hidden",
            opacity: "0"
        });

        button.innerText = buttonText["more"][langage];
    } else {
        element.css({
            height: "100%",
            visibility: "visible",
            opacity: "1"
        });

        button.innerText = buttonText["less"][langage];
    }
}

function setFilters(filter){
    var idArtist = null;
    var idMovement = null;
    var idCategory = null;

    if(filter.getAttribute("filter-type") == "artist"){
        idArtist = filter.getAttribute("data");
    } else if(filter.getAttribute("filter-type") == "movement") {
        idMovement = filter.getAttribute("data");
    } else {
        idCategory = filter.getAttribute("data");
    }

    $(".filter-item").removeClass("active");

    filter.classList.add("active");

    callAjaxSetFilters(idArtist, idMovement, idCategory);
}

function setFiltersContentPage(button){
    var url = button.getAttribute("href");
    var indexOf = url.indexOf("?page=");
    var page = parseInt(url.substring(indexOf + 6));

    var idArtist = null;
    var idMovement = null;
    var idCategory = null;

    if($("filter[name='idArtist']").length > 0){ //CopiedProducts
        idArtist = $("filter[name='idArtist']").attr("value");
        idMovement = $("filter[name='idMovement']").attr("value");
    } else { //OriginalProducts
        idCategory = $("filter[name='idCategory']").attr("value");
    }

    callAjaxSetFilters(idArtist, idMovement, idCategory, page);
}

function callAjaxSetFilters(idArtist, idMovement, idCategory, page = 1){
    ajaxBlur( $(".products-list-dynamic"), true);
    $('body').css('cursor', 'wait');
    $('body a').css('cursor', 'wait');

    var locale = $("html").attr("lang");
    var url = null;

    if(idCategory == null){
        url = window.location.protocol+"//"+window.location.hostname+"/"+locale+'/ajax/copied_products/set_filter';
    } else {
        url = window.location.protocol+"//"+window.location.hostname+"/"+locale+'/ajax/original_products/set_filter';
    }

    $.ajax({
        url: url,
        type: 'POST',
        data: jQuery.param({
            idArtist: idArtist,
            idMovement: idMovement,
            idCategory: idCategory,
            page: page
        }) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            $(".products-list-dynamic").html(jqxhr.twigRender);
            $(".artist-content-description.hidden-item").hide();

            $(".remove-filters").fadeIn();

            var elementPosition = $(".custom-breadcrumbs").offset().top;

            $("body, html").animate({
                scrollTop: elementPosition
            }, 500, "swing", function(){
               return ;
            });

            ajaxBlur( $(".products-list-dynamic"), false);

            jqxhr.artists.forEach(element => {
                var id = element['id'];
                $( ".filter-item-artist[data ="+ id +"]" ).addClass("active");
            });

            jqxhr.categories.forEach(element => {
                var id = element['id'];
                $( ".filter-item-category[data ="+ id +"]" ).addClass("active");
            });

            $('body').css('cursor', 'default');
            $('body a').css('cursor', 'pointer');

            //Sauvegarder les modifications pour la pagination
            if($("filter[name='idArtist']").length > 0) {
                $("filter[name='idArtist']").attr("value", idArtist);
                $("filter[name='idMovement']").attr("value", idMovement);
            } else {
                $("filter[name='idCategory']").attr("value", idCategory);
            }

            $(".pagination a").on("click", function(e) {
                e.preventDefault();
                setFiltersContentPage(this)
            });

            $(".share-montableau-product").on("click", function(e){
                e.preventDefault();
                new Modal().shareProduct(this);
            });

            $(".add-product-favorites").on("click", function(e){
                e.preventDefault();
                new Modal().addToFavoritesProduct(this);
            });

            $(".see-more-artist-description").on("click", function(e){
                e.preventDefault();
                var newHeight = $(".artist-content-description").outerHeight();
                console.log(newHeight);
                $(".artist-content-description").animate({
                    maxHeight: "1000000px"
                }, 500);
                $(this).remove();
            });
        },
        error: function () {
            console.log("Set Filter Error");
            return ;
        }
    });
}

function ajaxBlur(element, active=true){
    if(active == true){
        element.css({
            'filter'         : 'blur(5px)',
            '-webkit-filter' : 'blur(5px)',
            '-moz-filter'    : 'blur(5px)',
            '-o-filter'      : 'blur(5px)',
            '-ms-filter'     : 'blur(5px)'
        });
    } else {
        element.css({
            'filter'         : 'blur(0px)',
            '-webkit-filter' : 'blur(0px)',
            '-moz-filter'    : 'blur(0px)',
            '-o-filter'      : 'blur(0px)',
            '-ms-filter'     : 'blur(0px)'
        });
    }
}

function getSearchItems(){
    var locale = $("html").attr("lang");

    const searchInputAlerts = {
        "placeholder" : {
            "fr": "Rechercher sur tout le site",
            "en": "Search"
        },
        "noResults" : {
            "fr" : "Désolé... Nous n'avons rien trouvé",
            "en": "Sorry... No results"
        }
    };

    $.ajax({
        url: window.location.protocol+"//"+window.location.hostname+"/"+locale+'/ajax/get_search_items',
        type: 'POST',
        data: jQuery.param({}) ,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        success: function (jqxhr) {
            $(".input_section_search").html(jqxhr.twigRender);
            ajaxBlur( $(".input_section_search"), false);

            $('.search-on-all-website').select2({
                minimumInputLength: 3,
                language: {
                    inputTooShort: function () {
                        return searchInputAlerts['placeholder'][locale];
                    },
                    noResults: function () {
                        return searchInputAlerts['noResults'][locale];
                    },
                }
            });

            $(".search-on-all-website").on("change", function(){
                sendSearchData(this);
            });
        },
        error: function () {
            console.log("Get Search Section Error");
            return ;
        }
    });
}

function sendSearchData(select){
   window.location = window.location.protocol+"//"+window.location.hostname + select.value + "?search=true";
}

function removeAllFilters(){
    var url = window.location.toString();
    var index = url.indexOf("?");

    if(index != -1)
        url = url.substr(0, index);

    window.location = url;
}

export default listProducts;
