import $ from 'jquery';

function faqPagePart()
{
    if($(".pp_faq_questions").length != 0){
        $(".pp_faq_questions .item .title").on("click", function(){
            var id = this.getAttribute("data-id");
            console.log(id);
            if($(".pp_faq_questions .item-"+id).hasClass("active")){
                hideInfos(id);
            } else {
                getInfos(id);
            }
        });
    }
}

function getInfos(id){
    $(".pp_faq_questions .item-"+id).addClass("active");
    $(".pp_faq_questions .item-"+id+" .content").fadeIn();
}

function hideInfos(id){
    $(".pp_faq_questions .item-"+id).removeClass("active");
    $(".pp_faq_questions .item-"+id+" .content").fadeOut();
}


export default faqPagePart;
