import $ from "jquery";

export default class Modal {
    constructor(){}

    openShareModal(){
        $("#modal_social_network").fadeIn();
    }

    closeShareModal(){
        $("#modal_social_network").fadeOut();

        var url = window.location;
        var startID = "modal_social_network_";

        $("#"+startID+"facebook").attr("href", "https://www.facebook.com/sharer/sharer.php?u="+url);
        $("#"+startID+"twitter").attr("href", "https://twitter.com/intent/tweet?text="+url);
        $("#"+startID+"instagram").attr("href", "https://www.instagram.com/?url="+url);
        $("#"+startID+"mail").attr("href", "mailto:?&subject=Mon Tableau&body="+url);
    }

    setUrls(urlParam = null){
        var url = window.location;

        if(urlParam != null || urlParam == ""){
            url = window.location.protocol+"//"+window.location.hostname + urlParam;
        }

        var startID = "modal_social_network_";

        $("#"+startID+"facebook").attr("href", "https://www.facebook.com/sharer/sharer.php?u="+url);
        $("#"+startID+"pinterest").attr("href", "https://pinterest.com/pin/create/button/?url="+url+"&media=&description=");
        $("#"+startID+"instagram").attr("href", "https://www.instagram.com/?url="+url);
        $("#"+startID+"mail").attr("href", "mailto:?&subject=Mon Tableau&body="+url);
    }

    shareProduct(button){
        var url = button.getAttribute("data");
        this.setUrls(url);
        this.openShareModal();
        $(".background-close-modal").on("click", this.closeShareModal);
    }

    addToFavoritesProduct(button){
        $("#modal_alert_product_item .content h3").html("<div class='ajax-loader'></div>");
        $("#modal_alert_product_item").fadeIn();

        $(".background-close-modal").on("click", function(){
            $("#modal_alert_product_item").fadeOut();
        });

        let alert = {
            "fr": "Ajouté au favoris !",
            "en": "Add to Favorites !"
        }

        let id = button.getAttribute("data");
        var locale = $("html").attr("lang");
        $.ajax({
            url: '/ajax/add_to_favorites',
            type: 'POST',
            data: jQuery.param({
                id: id
            }) ,
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            success: function (jqxhr) {
                $("#modal_alert_product_item .content h3").html("<i class=\"fas fa-check\"></i><br>"+alert[locale]);
            },
            error: function () {
                $("#modal_alert_product_item").fadeOut();
                console.log("Get Error for Add To Favorites");
                return ;
            }
        });
    }
}